import { UiLegacy } from '@silvertours/front-legacy-shared';
import { GarantieWrapper } from './CarigamiGarantie.styles';

const Garantie = () => (
  <GarantieWrapper
    href="/conditions-garantie.htm"
    title="Garantie meilleur prix"
    target="_blank"
    rel="noopener noreferrer"
  >
    <UiLegacy.PixelIcon name="garantie" label="Garantie meilleur prix" />
  </GarantieWrapper>
);

export { Garantie };
